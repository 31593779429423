<template>
  <div>
    <div class="ct dd">
      <h2 style="border-bottom: none;">
        <router-link to="/" tag="i">←返回</router-link>
        密码管理
      </h2>
    </div>
    <div class="ct">
      <ul>
        <li>
          <router-link to="/password/password1" tag="div">登记密码</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <router-link to="/password/password2" tag="div">删除密码（用户号删除）</router-link>
        </li>
        <li>
          <router-link to="/password/password3" tag="div">删除密码（全部删除）</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
    name: 'rootPassword',
    data() {
        return {}
    },
    created() {


    },
}
</script>

